import { builder } from '@builder.io/sdk';
import builderConfig from '../../builder-config';

const apiKey = builderConfig?.builderAPIKey;

builder.init(apiKey); // Initialize Builder with your API key

export const getBuilderAPI = (model, apiKey, slug) => {
    switch (model) {
        //add more models here, if needed
        case 'page':
            return {
                fetchUrl: `https://cdn.builder.io/api/v3/content/${model}?apiKey=${apiKey}&url=${slug}&cachebust=true&includeRefs=true&includeSymbols=true`,
            };
        case 'symbol':
            return {
                fetchUrl: `https://cdn.builder.io/api/v3/content/${model}?apiKey=${apiKey}&cachebust=true&includeRefs=true&includeSymbols=true`,
            };
        default:
            return {
                fetchUrl: `https://cdn.builder.io/api/v3/content/${model}?apiKey=${apiKey}&query.data.pageUrl=${slug}&cachebust=true&includeRefs=true&includeSymbols=true`,
            };
    }
};

export const getBuilderData = async (builderModelName) => {
    // Check if the model name is provided
    if (!builderModelName) {
        throw new Error('Builder model name is required.');
    }

    try {
        const content = await builder
            .get(builderModelName, {
                apiKey: process.env.BUILDER_API_KEY,
            })
            // Convert the result to a promise
            .toPromise();

        return content; // Return the successful result
    } catch (error) {
        console.error('Error fetching builder data:', error.message || error);
    }
};

export const getAllBuilderData = async (builderModelName) => {
    // Check if the model name is provided
    if (!builderModelName) {
        throw new Error('Builder model name is required.');
    }

    try {
        const content = await builder.getAll(builderModelName, {
            apiKey: process.env.BUILDER_API_KEY,
        });

        return content; // Return the successful result
    } catch (error) {
        console.error('Error fetching builder data:', error.message || error);
    }
};

export const getBuilderPageModelsContent = async (builderModelName, urlPath) => {
    // Input validation
    if (!builderModelName) {
        throw new Error('Builder model name is required');
    }
    if (!urlPath) {
        throw new Error('URL path is required');
    }

    try {
        const content = await builder
            .get(builderModelName, {
                cachebust: true,
                userAttributes: {
                    urlPath: urlPath,
                },
            })
            .toPromise();

        return content;
    } catch (error) {
        // Log the error for debugging purposes
        console.error(
            `Failed to fetch page content for ${builderModelName} with URL path ${urlPath}: ${error.message}`,
        );
    }
};

export const getBuilderSectionModelsContent = async (builderModelName, slug) => {
    // Input validation
    if (!builderModelName) {
        throw new Error('Builder model name is required');
    }
    if (!slug) {
        throw new Error('Slug is required');
    }

    try {
        const content = await builder
            .get(builderModelName, {
                cachebust: true,
                query: {
                    data: {
                        slug: slug,
                    },
                },
            })
            .toPromise();

        return content;
    } catch (error) {
        // Log the error for debugging purposes
        console.log(`Failed to fetch content for ${builderModelName} with slug ${slug}: ${error.message}`);
    }
};
